<template>
  <div class="space-y-8">
    
    <div class="flex justify-center items-center">
      <div class="flex rounded-full items-center bg-yellow-100 space-x-1">
        <div class="pl-4 pr-2 py-2 text-sm font-jalnan">PlantosyDak #</div>
        <input
          class="w-32 p-2 bg-yellow-100"
          :placeholder="$t('#NUMBER_ONLY')"
          v-model="input_search"
        />
        <button
          class="rounded-r-full w-24 p-2"
          :class="btnClass"
          @click="onSearch(input_search)"
          :disabled="isNaN(Number(input_search))"
        >
          {{ $t("#BTN_SEARCH") }}
        </button>
      </div>
    </div>

    <div v-if="dataCurrent != null" class="rounded-xl bg-yellow-100">
      <div class="overflow-hidden">
        <img
          :style="$store.nftBgStyle(dataCurrent)"
          class="w-full rounded-t-xl"
          :src="dataCurrent.image"
        />
      </div>
      <div class="p-4 space-y-4">
        <div>
          <div class="font-jalnan font-bold text-2xl">
            {{ dataCurrent.name }}
          </div>

          <div v-if="dataCurrent.grade > 0" class="flex">
            <img :src="$store.getEggImage(dataCurrent, 1)" :class="eggClass" />
            <img :src="$store.getEggImage(dataCurrent, 2)" :class="eggClass" />
            <img :src="$store.getEggImage(dataCurrent, 3)" :class="eggClass" />
            <img :src="$store.getEggImage(dataCurrent, 4)" :class="eggClass" />
            <img :src="$store.getEggImage(dataCurrent, 5)" :class="eggClass" />
          </div>

          <div
            v-if="dataCurrent.description.length"
            v-html="md(dataCurrent.description)"
          ></div>
        </div>

        <div class="flex space-x-2">
          <div v-if="dataCurrent.parent_uid > 0">
            <button
              class="rounded-full px-2 py-1 bg-indigo-600 text-white text-sm"
              @click="onSearch(dataCurrent.parent_uid)"
              v-html="mdt_PDVIEW_PARENT_EGG(dataCurrent)"
            ></button>
          </div>

          <div v-if="existsKlaytn">
            <button
              class="rounded-full px-2 py-1 bg-indigo-600 text-white text-sm"
              @click="this.$router.push('/pdak/farm')"
              v-html="mdt_MENU_PDAK_FARM_LONG()"
            ></button>
          </div>
        </div>

        <div v-if="dataCurrent.attributes.length > 0" class="mt-4">
          <div class="font-bold">{{ $t("#ATTRIBUTES") }}</div>
          <div v-for="data in dataCurrent.attributes" :key="data.value">
            <div class="text-sm">{{ data.value }}</div>
          </div>
        </div>
        <div v-if="dataCurrent !== null">
          <div class="font-bold">{{ $t("#EXTERNAL_LINK") }}</div>
          <a :href="$store.get_opensea_url(dataCurrent.uid)" target="_blank"
            ><img
              class="h-8 px-2 py-1 shadow rounded-lg bg-gray-200"
              src="/img/opensea/openSea-full-Logo-dark-small.png"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      eggClass: "w-6 h-6",
      uid: null,
      input_search: "",

      dataCurrent: null,
      cacheDatas: {},
    };
  },
  mounted() {
    // console.log("mounted", this.$route.params);

    this.$store.commitRoot("set_ui", this.$route.fullPath);

    const input = this.$route.params.uid;
    this.uid = null;
    try {
      const num = Number(input);
      if (!isNaN(num)) {
        this.uid = num;
        this.input_search = input;
        this.onSearch(input);
      }
    } catch (err) {
      err;
    }
  },
  computed: {
    btnClass() {
      const n = Number(this.input_search);
      if (isNaN(n)) {
        return "bg-gray-300 text-white";
      }
      return "bg-indigo-400 text-white";
    },
    existsKlaytn() {
      console.log("existsKlaytn", window.klaytn);
      return window.klaytn !== undefined;
    },
  },
  methods: {
    mdt_MENU_PDAK_FARM_LONG() {
      const t_msg = this.$t("#MENU_PDAK_FARM_LONG");
      return this.md(t_msg);
    },
    mdt_PDVIEW_PARENT_EGG(d) {
      // <b>#{{ dataCurrent.parent_uid }}</b> 의
      //         {{ dataCurrent.egg_uid }} 번째 알
      const t_msg = this.$t("#PDVIEW_PARENT_EGG", {
        uid: d.parent_uid,
        egg_uid: d.egg_uid,
      });
      return this.md(t_msg);
    },
    // onSearchInput(event) {
    //   //   console.log("onSearchInput", event.keyCode, this.input_search);
    //   const start = 48; // 0
    //   const end = 57; // 9
    //   if (event.keyCode >= start && event.keyCode <= end) {
    //     return;
    //   }

    //   this.input_search = this.input_search.slice(
    //     0,
    //     this.input_search.length - 1
    //   );
    // },
    async onSearch(search_uid) {
      console.log("onSearch", search_uid);
      this.dataCurrent = null;

      if (isNaN(search_uid)) {
        // console.log("onSearch is not number", search_uid);
        // 숫자 아니면 가져올 데이터가 없으니
        this.$store.Alert("#ERR_NOT_FOUND_SEARCH");
        return;
      }

      // console.log("onSearch", search_uid, this.$store.GET_URL);
      // const url = `${this.$store.GET_URL}/pdview83/${search_uid}`;
      const url = `${this.$store.GET_URL}/pdv3view/${search_uid}`;
      const data = await this.$store.getAsync(url);
      if (data.code !== undefined && data.code != 100) {
        this.$store.AlertPost(data);
        return;
      }

      // console.log("getAsync ok", data);
      data.uid = search_uid;
      this.cacheDatas[search_uid] = data;
      this.dataCurrent = data;
      // console.log("data", data);
    },
  },
};
</script>

<style scoped>
.tw-flex2 {
  @apply p-4 rounded-xl flex-none md:flex justify-center items-center;
}
.tw-bg-yellow {
  @apply bg-yellow-300 dark:bg-yellow-800 dark:text-white;
}
</style>
